import React, { useState } from 'react';
import Modal from 'react-modal';
import { AreaIdName } from '../../types/Area';
import { useRecoilState } from 'recoil';
import { compareFavoritesState } from '../../state/areasState';
import { CompareFavorite } from '../../types/Favorites';
import 'tailwindcss/tailwind.css';
import toast from 'react-hot-toast';

interface FavoritesModalProps {
  isOpen: boolean;
  onClose: () => void;
  areasIdName: AreaIdName[];
}

const FavoritesModal: React.FC<FavoritesModalProps> = ({ isOpen, onClose, areasIdName}) => {
    const [favoriteName, setFavoriteName] = useState('');
    const [compareFavorites, setCompareFavorites] = useRecoilState(compareFavoritesState);

    const handleConfirm = () => {
        setFavoriteName('');
        const newFavorite: CompareFavorite = {
            name: favoriteName, 
            areasIdName
        };
        setCompareFavorites(() => {
            // Create a new array by combining the existing array and the new element
            const newArray = [...compareFavorites, newFavorite];
  
            // Sort the new array alphabetically based on the 'name' property
            const sortedArray = newArray.sort((a, b) => a.name.localeCompare(b.name));
  
            // Return the sorted array to update the state
            return sortedArray;
        });
        toast.success(`Successfully added the comparison ${favoriteName} to the favorites list!`);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-2 border-primary p-4 rounded-md shadow-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            shouldCloseOnOverlayClick={true}
        >
            <div className="p-4">
                <h2 className="text-lg font-semibold text-black mb-4">Add current comparison to favorites?</h2>
              
                <ul className='list-disc mx-5 mb-4'>
                    {areasIdName && areasIdName.map((areaIdName: AreaIdName) => 
                        <li className='font-semibold text-black' key={`favorites-list-item-${areaIdName.id}`}>
                            {areaIdName.name}
                        </li>
                    )}
                </ul>
                <p className="mb-4 text-black">What would you like to name this comparison?</p>
                
                <input
                    type="text"
                    value={favoriteName}
                    onChange={(e) => setFavoriteName(e.target.value)}
                    className="w-full border p-2 mb-4"
                    placeholder="Enter name"
                />
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-1 border-2 rounded-md button shadow-m ho"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="px-4 py-1 border-2 rounded-md button shadow-m"
                    >
                      Confirm
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default FavoritesModal;