import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { AREAS_DAILY_HOURLY_CURRENT_FORECASTS } from '../../graphql/areas/queries';
import Loading from '../common/Loading';
import { navState } from '../../state/navState';
import { areaState, compareAreasIdNameState, compareFavoritesState, showWeeklyForecastState } from '../../state/areasState';
import DailyForecastsCompare from '../Forecast/DailyForecastsCompare';
import HourlyForecastsCompare from '../Forecast/HourlyForecastsCompare';
import HourlyForecasts from '../Forecast/HourlyForecasts';
import { DailyForecast, ForecastsByArea, HourlyForecast } from '../../types/Forecast';
import { sortForecastsByAreaId, sortForecastsByDate } from '../../utils/WeatherUtils';
import DateButtons from '../buttons/DateButtons';
import { getDisplayDate } from '../../utils/DateUtil';
import ClimbitChart from '../charts/ClimbitChart';
import CardCarousel from '../cards/CardCarousel';
import TableCarousel from '../common/TableCarousel';
import { FaHeartCircleMinus, FaHeartCirclePlus } from 'react-icons/fa6';
import FavoritesModal from './FavoritesModal';
import { AreaIdName } from '../../types/Area';
import CompareFavoritesComboboxComponent from './CompareFavoritesCombobox';
import { CompareFavorite } from '../../types/Favorites';
import Tooltip from '../common/Tooltip';
import { compareAreasState } from '../../state/compareAreasState';
import { useNavigate } from 'react-router';
import PastPrecipComponent from '../Forecast/PastPrecip';
import GoogleMapsButton from '../buttons/GoogleMapsButton';
import MountainProjectButton from '../buttons/MountainProjectButton';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { sunShadeState } from '../../state/sunShadeState';
import SunShadeComponent from '../area/SunShadeComponent';
import { Switch } from '@headlessui/react';

type CompareAreasProps = {
    areaIds: string[],
}

const findCurrentFavorite = (areasIdName: AreaIdName[], recoilState: typeof compareFavoritesState): CompareFavorite | undefined => {
    const currentFavorites = useRecoilValue(recoilState);
    return  currentFavorites.find((favorite:any) => compareAreasIdNames(favorite.areasIdName, areasIdName));
}; 

const compareAreasIdNames = (arr1: AreaIdName[], arr2: AreaIdName[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = [...arr1].sort((a, b) => a.id.localeCompare(b.id) || a.name.localeCompare(b.name));
    const sortedArr2 = [...arr2].sort((a, b) => a.id.localeCompare(b.id) || a.name.localeCompare(b.name));
    return sortedArr1.every((item1, index) => item1.id === sortedArr2[index].id && item1.name === sortedArr2[index].name);
};


const CompareAreas = ({areaIds}: CompareAreasProps) => {
    const [compareAreasIdName, setCompareAreasIdName] = useRecoilState(compareAreasIdNameState);
    const [compareAreas, setCompareAreas] = useRecoilState(compareAreasState);
    const [area, setSelectedArea] = useRecoilState(areaState);
    const resetNavState = useResetRecoilState(navState);
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const {temperaturePreferences, metricUnits} = useRecoilValue(unitPreferencesState);
    const [selectedView, setSelectedView] = useState('graph'); 
    const [isFavoritesModalOpen, setIsFavoritesModalOpen] = useState(false); 
    const [compareFavorites, setCompareFavorites] = useRecoilState(compareFavoritesState);
    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
    const dailyForecastRef = useRef<HTMLDivElement>(null);
    const [currentDay, setCurrentDay] = useState<any>(null);
    const [dailyForecast, setDailyForecast] = useState<DailyForecast[]>([]);
    const [bestTimeToClimb, setBestTimeToClimb] = useState<HourlyForecast[]>([]);
    const [hourlyForecast, setHourlyForecast] = useState<HourlyForecast[]>([]);
    const [areaHourlyForecast, setAreaHourlyForecast] = useState<HourlyForecast[]>([]);
    const [weeklyHourlyForecast, setWeeklyHourlyForecast] = useState<HourlyForecast[][]>([]);
    const [hourlyForecastByAreaId, setHourlyForecastByAreaId] = useState<ForecastsByArea>({});
    const [showWeeklyForecast, setShowDailyForecast] = useRecoilState(showWeeklyForecastState);
    const dailyForecasts = sortForecastsByDate(compareAreas, 'dailyForecast');
    const hourlyForecasts = sortForecastsByDate(compareAreas, 'hourlyForecast');
    const bestTimesToClimb = sortForecastsByDate(compareAreas, 'bestTimeToClimb');
    const forecastLength = Object.keys(dailyForecasts).length;
    const navigate = useNavigate();
    const sunShade = useRecoilValue(sunShadeState);

    const { data, loading, error, refetch: refetchQuery } = useQuery(AREAS_DAILY_HOURLY_CURRENT_FORECASTS, {
        variables: {
            ids: compareAreasIdName.map((areaIdName) => areaIdName.id),
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShades: compareAreasIdName.map((areaIdName) => sunShade[areaIdName.id] || 0)
        },
    });
    
    const handleRemoveClick = (compareAreaIdNamesToRemove: AreaIdName[]) => {
        setCompareFavorites(compareFavorites.filter((favorite) => !compareAreasIdNames(favorite.areasIdName, compareAreaIdNamesToRemove)));
    };

    const handleFavoritesSelect = (newAreasIdName: AreaIdName[]) => {
        setCompareAreasIdName((newAreasIdName));
        setSelectedArea(undefined);
        setSelectedView('graph');
    };


    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
      
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]); // Run this effect whenever preferences change

    // Function to handle hash changes
    const handleHashChange = () => {
        const hash = window.location.hash;
        if (hash) {
            const areaId = hash.replace('#climbit', '');
            setSelected(areaId);
            const element = document.getElementById(hash.substring(1));
            if (element && element.getBoundingClientRect()) {
            // Calculate the scroll position, adjusting for the fixed navigation bar
                const scrollPosition = element.getBoundingClientRect().top + window.scrollY - 60;

                // Scroll to the adjusted position
                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    };

    useEffect(() => {
        if (compareAreas.length > 0) handleHashChange();
    }, [window.location.hash, compareAreas]);
    
    const currentFavorite: CompareFavorite | undefined = findCurrentFavorite(compareAreasIdName, compareFavoritesState);


    const setSelected = (selectedValue: string) => {
        const targetArea = compareAreas.find(area  => area.id === selectedValue);
        if (targetArea) {
            setSelectedView(selectedValue);
            setSelectedArea(targetArea);
            if(targetArea.forecasts && targetArea.forecasts.hourlyForecast) {
                setAreaHourlyForecast(targetArea.forecasts.hourlyForecast.filter((hourlyForecast: HourlyForecast) => hourlyForecast.date === currentDay));
                navigate(`#climbit${targetArea.id}`);
            }
        } else {
            setSelectedArea(undefined);
            setSelectedView(selectedValue);
            navigate('');
        }   
    };

    useEffect(() => {
        resetNavState();
        if (areaIds && areaIds.length > 0 && data && data.areasById) {
            setCompareAreas(data.areasById);
            setWeeklyHourlyForecast(data.areasById.map((area: any) => area?.forecasts?.hourlyForecast?.filter((_:any, i:number) => (i % 4 === 0))));
        }
        if (currentDay) {
            setDailyForecast(dailyForecasts[currentDay]);
            setBestTimeToClimb(bestTimesToClimb[currentDay]);
            setHourlyForecast(hourlyForecasts[currentDay]);
            setHourlyForecastByAreaId(sortForecastsByAreaId(hourlyForecasts[currentDay]));
            if (area && area.forecasts && area.forecasts.hourlyForecast) {
                setAreaHourlyForecast(area.forecasts.hourlyForecast.filter((hourlyForecast: HourlyForecast) => hourlyForecast.date === currentDay));
            }
        }
    }, [data, areaIds, compareAreas, currentDay]);

    useEffect(() => {
        if(currentDayIndex === undefined || !compareAreas) return;
        const keys = Object.keys(dailyForecasts);
        setCurrentDay(keys[currentDayIndex]);
    }, [currentDayIndex, compareAreas]);

    if (loading) return <Loading/>;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent/>;
    return (
        <div className="page-background">  
            <div className='flex w-full flex-col md:flex-row'>
                <div className='justify-center md:justify-start w-full flex pl-6 pb-3 md:pb-0'>
                    {compareFavorites.length > 0 &&  (
                        <CompareFavoritesComboboxComponent onChange={(newAreasIdName: AreaIdName[]) => handleFavoritesSelect((newAreasIdName))} placeholderText='Search Favorite Comparisons...'/>
                    )}
                </div>
                <div className='md:pr-6 justify-center md:justify-end w-full flex'>
                    {!currentFavorite && (
                        <div className='flex flex-row space-x-2'>
                            <Tooltip text="Favorite Comparison" className='text-sm text-center' position='-top-7 -mt-8'>
                                <button className='px-4 py-1 border-2 rounded-md button shadow-m' onClick={() => setIsFavoritesModalOpen(true)}>   
                                    <FaHeartCirclePlus size={25}/>
                                </button>
                            </Tooltip>
                        </div>
                    
                    )}
                    {currentFavorite && (
                        <Tooltip text="Remove Favorite" className='text-sm text-center' position='-top-7 -mt-8'>
                            <button className='px-4 py-1 border-2 rounded-md button shadow-m' onClick={() => handleRemoveClick(compareAreasIdName)}>   
                                <div className='flex flex-row space-x-2'>
                                    <FaHeartCircleMinus className='fill-red' size={25}/>
                                    <p>{currentFavorite.name}</p>
                                </div>
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
            
            <FavoritesModal isOpen={isFavoritesModalOpen} areasIdName={compareAreasIdName} onClose={() => setIsFavoritesModalOpen(false)}/>

            <div className='px-1 py-4'>
                { compareAreasIdName && (
                    <div>
                        
                        <div className='flex flex-col'>
                            { compareAreasIdName.length > 0 && dailyForecasts && hourlyForecasts && currentDayIndex !== undefined && currentDay && (
                                <DateButtons
                                    currentIndex={currentDayIndex}
                                    setCurrentIndex={setCurrentDayIndex}
                                    length={forecastLength || 0}
                                    date={getDisplayDate(new Date(currentDay))}
                                />
                            )}  
                            <DailyForecastsCompare 
                                allHourlyForecasts={hourlyForecast ?? []}
                                dailyForecasts={dailyForecast}
                                bestTimesToClimb={bestTimeToClimb}
                                areas={compareAreas}
                                onClick={(areaId: string) => { areaId !== selectedView ? setSelected(areaId) : setSelected('graph');}}
                                currentArea={area}
                                setSelected={setSelected}
                            />
                            
                           
                            
                            {hourlyForecast && weeklyHourlyForecast && compareAreasIdName && compareAreasIdName.length > 0 && hourlyForecast.length  > 0 && weeklyHourlyForecast.length  > 0 && (
                                <>
                                    <div className="flex self-center md:self-end flex-row items-center mb-2 px-2 md:px-4">
                                        <span className='font-semibold text-lg'>Daily</span>
                                        <Switch
                                            checked={showWeeklyForecast}
                                            onChange={() => setShowDailyForecast(!showWeeklyForecast)}
                                            className={`${showWeeklyForecast ? 'bg-primary' : 'bg-gray opacity-70'}
                    relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${showWeeklyForecast ? 'translate-x-8' : 'translate-x-0'}
                        pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <span className='font-semibold text-lg pl-2'>Weekly</span>
                                    </div>
                                    <div id={`climbit${selectedView}`}>
                                        <ClimbitChart
                                            hourlyForecasts={hourlyForecast} 
                                            weeklyHourlyForecast={weeklyHourlyForecast} 
                                            selectedArea={ area?.name ?? undefined} 
                                            showWeeklyForecast={showWeeklyForecast}
                                            multipleAreas={true}
                                        />
                                    </div>
                                </>
                               
                            )}
                        </div>

                        {hourlyForecast && compareAreasIdName.length > 0 && hourlyForecast.length > 0 &&  (
                            <>
                                <div className='w-full justify-center md:justify-end flex'>
                                    <select id="views" value={selectedView} onChange={e =>setSelected(e.target.value)} className="flex border border-gray text-gray text-sm rounded-lg appearance-none m-4 focus:ring-primary focus:border-primary md:mr-10">
                                        <option value="graph">Graph Comparison View</option>
                                        <option value="table">Table Comparison View</option>
                                        {compareAreas.map((area) => (
                                            <option value={area.id} key={`option-${area.id}`}>
                                                {area.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='pb-2'>
                                    {(selectedView === 'graph' || selectedView === 'table') && (
                                        <DateButtons
                                            currentIndex={currentDayIndex}
                                            setCurrentIndex={setCurrentDayIndex}
                                            length={forecastLength || 0}
                                            date={getDisplayDate(new Date(currentDay))}
                                        />
                                    )}
                                </div>

                                {selectedView === 'graph' && hourlyForecast && hourlyForecast.length > 0 && (
                                    <div>
                                        <HourlyForecastsCompare
                                            forecasts={hourlyForecast}
                                            weeklyHourlyForecast={weeklyHourlyForecast}
                                            showWeeklyForecast={showWeeklyForecast}
                                        />
                                    </div>
                                )}
                                {selectedView === 'table' && (
                                    <div className='overflow-x-scroll'>
                                        {currentDay && hourlyForecastByAreaId && (
                                            <div className='max-h-[40rem]'>
                                                <TableCarousel hourlyForecastsByAreaId={hourlyForecastByAreaId} bestTimesToClimb={bestTimeToClimb}/>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {area && (
                                    <div>
                                        <div className='flex flex-col space-y-3 justify-center items-center pb-2'>
                                            <div className='flex flex-row space-x-2 justify-center mb-2 md:mb-0'>
                                                {(area.forecasts &&  area.forecasts.pastPrecip) && (
                                                    <PastPrecipComponent pastPrecip={area.forecasts.pastPrecip}/>
                                                )}
                                                {area.mountainProject && (
                                                    <MountainProjectButton area={area}/>
                                                )}
                                                {area.googleMaps && (
                                                    <GoogleMapsButton areaCity={area}/>
                                                )}           
                                            </div>
                                            <div className='flex justify-center items-center md:py-4 md:ml-12'>
                                                <SunShadeComponent areaId={area.id}/>
                                            </div>

                                        </div>
                                        
                                        <div className="flex flex-col  pb-3 hide-scroll-bar flex-shrink-0 items-center justify-center">
                                            <CardCarousel dailyForecasts={area.forecasts?.dailyForecast} bestTimesToClimb={area.forecasts?.bestTimesToClimb?.hourlyForecast} currentDayIndex={currentDayIndex} onClick={(id: number) => setCurrentDayIndex(id)} displayAreaName={true} refToScroll={dailyForecastRef} allHourlyForecasts={area.forecasts?.hourlyForecast}/>
                                        </div>
                                        { area.forecasts && area.forecasts.hourlyForecast && (
                                            <div className='md:px-10'ref={dailyForecastRef} >
                                                <DateButtons
                                                    currentIndex={currentDayIndex}
                                                    setCurrentIndex={setCurrentDayIndex}
                                                    length={area.forecasts?.dailyForecast?.length || 0}
                                                    date={getDisplayDate(new Date(currentDay))}
                                                />
                                                <HourlyForecasts
                                                    forecasts={areaHourlyForecast}
                                                    bestTimeToClimb={bestTimeToClimb.find((hourlyForecast) => hourlyForecast.areaId === area.id) }
                                                    sunrise={area.forecasts.dailyForecast && area.forecasts.dailyForecast[currentDayIndex] ? new Date(area.forecasts.dailyForecast[currentDayIndex].sunrise) : undefined}
                                                    sunset={area.forecasts.dailyForecast && area.forecasts.dailyForecast[currentDayIndex] ? new Date(area.forecasts.dailyForecast[currentDayIndex].sunset) : undefined}
                                                    weeklyHourlyForecast={weeklyHourlyForecast}
                                                    showWeeklyForecast={showWeeklyForecast}
                                                />
                                            </div> 
                                        )}
                                    </div>
                                )}
                            </>
                        )}  
                    </div>
                )}
            </div> 
        </div>
    );
};

export default CompareAreas;