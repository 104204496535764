export default function TooManyRequestsComponent() {

    return <div
        className="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-gradient-to-r
    from-primary
    to-primary-dark
    md:px-20
    p-4
  "
    >
        <div className="md:px-40 px-5 md:py-20 py-5 bg-white rounded-md shadow-xl">
            <div className="flex flex-col items-center">
                <h6 className="mb-2 text-2xl font-bold text-center text-gray md:text-3xl">
                    <span className="text-red">Oops!</span> Too Many Requests
                </h6>

                <p className="mb-8 text-center text-gray md:text-lg">
                    You have made too many requests in the last 15 minutes. Please try again after 15 minutes have elapsed
                </p>

                <a
                    href="/"
                    className="px-6 py-2 text-sm font-semibold text-white bg-primary hover:bg-primary-dark"
                >Go home
                </a>
            </div>
        </div>
    </div>;

}



