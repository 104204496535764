import { useRecoilState } from 'recoil';
import AreasComboboxComponent from '../areas/AreasCombobox';
import { addAreaIdName, compareAreasIdNameState, } from '../../state/areasState';
import toast, { Toaster } from 'react-hot-toast';
import { AreaIdName } from '../../types/Area';
import { City } from '../../types/City';

type AreaSearchCardProps = {
    id: number;
    card?:any;
    className?: string;
};

export default function AreaSearchCardComponent({id, card, className='card-layout'}: AreaSearchCardProps) {
    const [compareAreasIdName, setCompareAreasIdNames] = useRecoilState(compareAreasIdNameState);

    const addAreaFromSearch =  (selectedAreaCity: AreaIdName | City)=> {
        if (selectedAreaCity && 'id' in selectedAreaCity) {
            if(compareAreasIdName.map(areaIdName => areaIdName.id).includes(selectedAreaCity.id)){
                toast('Area already exists on Compare Page', {
                    duration: 3500,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                });
            } else {
                addAreaIdName(selectedAreaCity, compareAreasIdName, setCompareAreasIdNames);
            }
        } 
    };
    return(
        <div ref={card} className={`${className} mb-1 mt-1 md:min-w-[12rem] md:min-h-[12rem] min-w-full h-[10rem] relative`} key={`area-search-${id}`}>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName="toast"
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3500,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                }}
            />
            <div className='top-0 mt-2 w-full md:px-0.5 px-4'>
                <AreasComboboxComponent showCurrentLocation={false} includeCities={false} placeholderText='Add Location...' onChange={(
                    selectedAreaIdName: AreaIdName | City
                ) => addAreaFromSearch(selectedAreaIdName)
                }/>
            </div>
            <div>
                <div className='text-xl font-bold md:mt-4 mt-2'>
                    Compare Another Area
                </div>
                <div className='md:pt-4 pt-2 md:text-lg text-[1rem] text-gray-dark'>
                    Type a location in the search bar above to add it to the comparison!
                </div>
            </div>
        </div>
    );
}
